<template>
  <div>
    <CRow>
      <CCol
        v-for="field in statisticFields"
        :key="field.key"
        col="12"
        md="6"
        lg="6"
        xl="4"
        class="mb-3"
      >
        <TWidgetSimple
          :title="field.label"
          clickable
          :color="field.color"
          alignment="left"
          :number="statistics[field.key] && statistics[field.key].skus_quantity"
          @click="selected = field.key"
          :selected="selected == field.key"
          :loading="statisticsLoading"
          class="h-100"
        >
          <template #title-right>
            <CIcon
              v-c-tooltip="{
                content: $t(field.tooltip),
                placement: 'left',
              }"
              name="cis-info-circle"
              class="ml-auto mr-2"
            />
          </template>
          <template #bottom-content>
            <TTableAsForm
              v-if="statistics[field.key]"
              :data="statistics[field.key]"
              :fields="fields"
              :splitLeft="6"
              :addRowClasses="[]"
            >
              <template #boxes_quantity="{ value }">
                <TMessageNumber :value="value" class="h5 m-0" />
              </template>
              <template #weight="{ value }">
                <TMessageNumber :value="value" class="h5 m-0" suffix="kg" />
              </template>
              <template #volume="{ value }">
                <TMessageVolume :value="value" class="h5 m-0" />
              </template>
            </TTableAsForm>
          </template>
        </TWidgetSimple>
      </CCol>
    </CRow>

    <CProgress
      class="mb-5"
      :max="100"
      color="dark"
      :value="100"
      style="height: 3px"
    />
    <FilterGoodsStatistic
      :store="store"
      @update:filter="filter = $event"
      :loading="statisticsLoading || loading"
    />
    <CRow class="d-flex align-items-stretch position-relative my-4">
      <SCardSku
        v-for="box in boxes"
        :key="box.id"
        :box="box"
        enterable
        :showItem="true"
        class="my-3 mx-3"
        :noItems="false"
        :showWeight="false"
        :showSfa="false"
        showOwner
      />
      <CElementCover class="my-4" :opacity="0.4" center v-if="loading">
        <div class="d-flex">
          <TMessage content="Loading" class="mr-2 h3" />
          <CSpinner size="2xl" color="success" />
        </div>
      </CElementCover>
    </CRow>
    <CCol v-show="paginate.last > 1" col="12" class="mt-2">
      <TPagination :store="`warehouse.${store}`" />
    </CCol>
  </div>
</template>

<script>
import FilterGoodsStatistic from "../../components/FilterGoodsStatistic.vue";
export default {
  components: { FilterGoodsStatistic },
  data() {
    return {
      selected: "",
      statisticFields: [
        {
          key: "all_skus",
          label: "Total SKUs",
          color: "info",
          tooltip: "All goods in stock",
        },
        {
          key: "skus_doesnt_have_owner",
          label: "Goods not yet distributed",
          color: "success",
          tooltip:
            "Goods have not been distributed or have not been fully distributed for the order",
        },
        {
          key: "skus_has_owner",
          label: "Distributed goods",
          color: "primary",
          tooltip: "Distributed goods",
        },
        {
          key: "skus_not_in_lading_bill_with_expected",
          label: "Goods not on the lading bills",
          color: "dark",
          tooltip:
            "Goods have not been on the lading bills or have not been fully loaded",
        },
        {
          key: "skus_not_in_picker_compared_with_expected",
          label: "Goods not load into containers yet",
          color: "danger",
          tooltip:
            "Goods have not been loaded into the container or have not loaded up as expected (at lading bills)",
        },
      ],
      fields: [
        { key: "boxes_quantity", label: "Total number of boxes" },
        { key: "weight", label: "Total weight" },
        { key: "volume", label: "Total volume" },
      ],
      statistics: {},
      statisticsLoading: false,
      filter: {},
    };
  },
  created() {
    this.selected = "all_skus";
  },
  watch: {
    selected: {
      immediate: false,
      handler() {
        this.lodash.appendRouteQuery(this.$router, {});
      },
    },
    filterBoxes(value) {
      if (!this.lodash.isEqual(value, this.filter)) {
        this.fetchStore();
      }
    },
    filter(value) {
      this.fetchStatistics(value);
      if (!this.lodash.isEqual(this.filterBoxes, value)) {
        this.fetchStore();
      }
    },
  },
  computed: {
    boxes() {
      return this.$store.getters[`warehouse.${this.store}.list`];
    },
    paginate() {
      return this.$store.getters[`warehouse.${this.store}.paginate`];
    },
    filterBoxes() {
      return this.lodash.omit(
        this.$store.state[this.store][`warehouse_${this.store}_query`],
        ["page"],
        ["owners"]
      );
    },
    loading() {
      return this.$store.getters[`warehouse.${this.store}.loading`];
    },
    store() {
      switch (this.selected) {
        case "all_skus":
          return "boxes_management";
        case "skus_has_owner":
          return "boxes_has_owners";
        case "skus_doesnt_have_owner":
          return "boxes_doesnt_have_owner";
        case "skus_not_in_lading_bill_with_expected":
          return "boxes_not_in_lading_bills";
        default:
          return "boxes_without_container";
      }
    },
  },
  methods: {
    fetchStatistics(filter) {
      this.statisticsLoading = true;
      this.$tomoni.warehouse.boxes_statistics
        .all(filter)
        .then((data) => {
          this.statistics = data.data;
        })
        .finally(() => {
          this.statisticsLoading = false;
        });
    },
    fetchStore() {
      this.$store.dispatch(`warehouse.${this.store}.apply-query`, {
        ...this.filter,
        include: "children,parent,tags,owners,owners.order,owners.user",
      });
    },
  },
};
</script>
