<template>
  <div>
    <CCard class="mt-3">
      <CCardHeader>
        <TMessage content="Search" bold />
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol col="12" sm="6" md="3" class="mb-3">
            <TInputText
              label="Tracking"
              placeholder="Tracking Id"
              :value.sync="filter.tracking"
            />
          </CCol>
          <CCol col="12" sm="6" md="3" class="mb-3">
            <TInputText
              label="SKU Id"
              placeholder="SKU Id"
              :value.sync="filter.id"
            />
          </CCol>
          <CCol col="12" sm="6" md="3" class="mb-3">
            <SSelectBoxTags label="Tags" :value.sync="filter['tags.id']" />
          </CCol>
          <CCol col="12" sm="6" md="3" class="mb-3">
            <SSelectBoxType
              label="Type"
              :value.sync="boxType"
              @change="selectBoxType"
              :append="[
                {
                  id: 'is_not_child_box',
                  name: $t('Remove the children box'),
                },
              ]"
            />
          </CCol>
          <CCol col="12" sm="6" md="3" class="mb-3">
            <SSelectSfa label="SFA" :value.sync="filter.sfa_id" prependAll />
          </CCol>
          <CCol col="12" sm="6" md="3" class="mb-3">
            <TInputText
              label="Goods"
              placeholder="Product Id"
              :value.sync="filter['items.product_id']"
            />
          </CCol>
          <CCol col="12" sm="6" md="3" class="mb-3">
            <SSelectCustomer
              :value.sync="filter['owners.user_id']"
              label="Customer"
              noCustomLabel
              prependAll
            />
          </CCol>
          <CCol col="12" sm="6" md="3" class="mb-3">
            <SSelectLadingBill
              label="Lading bill"
              :value.sync="filter['ladingBills.id']"
              :prepend="[{ id: '', name: $t('All') }]"
            />
          </CCol>
          <CCol col="12" sm="6" md="3" class="mb-3">
            <SSelectInvoice
              prependAll
              :value.sync="filter['ladingBills.container_id']"
              label="Invoice"
            />
          </CCol>
          <CCol col="12" sm="6" md="3" class="mb-3">
            <SSelectGoodsDelivery
              label="Goods delivery"
              :value.sync="filter['pivotGoodsDeliveries.goods_delivery_id']"
              :prepend="[{ id: '', name: $t('All') }]"
            />
          </CCol>
          <CCol col="12" sm="6" md="6" class="mb-3">
            <TMessage content="Dates" class="mb-2" bold />
            <CRow>
              <CCol col="12" md="6">
                <TSelect
                  class="m-0"
                  placeholder="Select"
                  :options="[
                    { id: '30_days', name: $t('Last 30 days') },
                    { id: '90_days', name: $t('Last 90 days') },
                    { id: 'all_time', name: $t('All time') },
                    { id: 'custom', name: $t('Custom') },
                  ]"
                  :value="timelineSelected"
                  @select="onSelect"
                />
              </CCol>
              <CCol col="12" md="6">
                <TInputDateTimeRange
                  v-if="timelineSelected.id != 'all_time'"
                  :value.sync="dateRange"
                  :disabled="timelineSelected.id != 'custom'"
                  @update:value="setCreatedAtFilter"
                />
              </CCol>
            </CRow>
          </CCol>
        </CRow>
        <div class="float-right mt-3">
          <SButtonClearFilter @click="clearFilter" />
          <SButtonSearchFilter
            class="ml-2"
            :disabled="loading"
            @click="search"
          />
        </div>
      </CCardBody>
    </CCard>
    <TMessageNoData v-if="!list.length && active && !loading" />
  </div>
</template>

<script>
export default {
  props: {
    store: {
      type: String,
      default: "boxes",
    },
    defaultFilter: {
      type: Object,
      default: () => {
        return {
          include: "children,parent,tags,owners,owners.order,owners.user",
        };
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filter: {},
      filter_between: {},
      active: false,
      dateRange: {
        start: null,
        end: null,
      },
      timelineSelected: { id: "30_days", name: this.$t("Last 30 days") },
      boxType: "",
    };
  },
  created() {
    this.dateRange = this.defaultTimes;
    this.filter_between["created_at"] = this.getTimes(this.defaultTimes);
    this.search();
  },
  watch: {
    timelineSelected(value) {
      if (value.id == "30_days") {
        this.dateRange = this.defaultTimes;
        this.filter_between["created_at"] = this.getTimes(this.dateRange);
        return;
      }
      if (value.id == "90_days") {
        this.dateRange = this.getTimeline(90);
        this.filter_between["created_at"] = this.getTimes(this.dateRange);
        return;
      } else {
        this.dateRange = { start: "infinity", end: "infinity" };
        this.filter_between["created_at"] = this.getTimes(this.dateRange);
      }
    },
  },
  computed: {
    list() {
      return this.$store.getters[`warehouse.${this.store}.list`];
    },
    defaultTimes() {
      return {
        start: this.lodash.toTimeStamp(
          Math.floor(new Date(new Date().setDate(new Date().getDate() - 30))),
          "start"
        ),
        end: this.lodash.toTimeStamp(Math.floor(Date.now()), "end"),
      };
    },
  },
  methods: {
    search() {
      this.active = true;
      this.$router.push({}).catch(() => {});
      const filterList = this.lodash.mapKeys(
        this.filter,
        function (value, key) {
          return `filter[${key}]`;
        }
      );
      const filterBetween = this.lodash.mapKeys(
        this.filter_between,
        function (value, key) {
          return `filter_between[${key}]`;
        }
      );
      const query = this.lodash.pickBy({
        ...filterList,
        ...filterBetween,
        ...this.defaultFilter,
      });
      this.$emit("update:filter", query);
    },
    setCreatedAtFilter({ start, end }) {
      this.filter_between["created_at"] = `${start},${end}`;
    },
    clearFilter() {
      this.filter = {};
      this.filter_between = {};
      this.boxType = "";
      this.clearTimes();
    },
    getTimes({ start, end }) {
      return `${start},${end}`;
    },
    getTimeline(numDays) {
      return {
        start: this.lodash.toTimeStamp(
          Math.floor(
            new Date(new Date().setDate(new Date().getDate() - numDays))
          ),
          "start"
        ),
        end: this.lodash.toTimeStamp(Math.floor(Date.now()), "end"),
      };
    },
    clearTimes() {
      this.dateRange = this.defaultTimes;
      this.timelineSelected = { id: "30_days", name: this.$t("Last 30 days") };
      this.filter_between["created_at"] = this.getTimes(this.defaultTimes);
    },
    onSelect(value) {
      this.timelineSelected = value;
    },
    selectBoxType(value) {
      this.filter = this.lodash.omit(this.filter, [
        "is_parent_box",
        "is_child_box",
      ]);
      if (!value.id) return;
      if (value.id == "is_not_child_box") this.filter["is_child_box"] = "0";
      else this.filter[value.id] = 1;
    },
  },
};
</script>
